'use client';

import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { useEffect } from 'react';
import { useConfig } from '@/app/contexts/ConfigContext';
import { LanguageOptions } from '@/types';
import FaviconSetter from '@/components/FaviconSetter';

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { config, setLanguageAndWording } = useConfig();

  useEffect(() => {
    const fetchConfig = async () => {
      const browserLang = navigator.language;
      const langCode = browserLang
        .split('-')[0]
        .toUpperCase() as LanguageOptions;
      const isBrowserLangAvailableInConfig =
        config.languages.includes(langCode);

      if (isBrowserLangAvailableInConfig) {
        setLanguageAndWording(config.serviceType, langCode);
      } else if (!isBrowserLangAvailableInConfig) {
        setLanguageAndWording(config.serviceType, config.languages[0]);
      } else {
        console.error('Error using config');
      }
    };
    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <FaviconSetter faviconUrl={config.theme?.faviconSrc} />
      {children}
      <Footer />
    </>
  );
}
