'use client';

import { useEffect, useState } from 'react';
import { useConfig } from '@/app/contexts/ConfigContext';
import CustomLink from './CustomLink';
import { SERVICE_TYPES } from '@/helpers/enums';
import LanguageSelection from './LanguageSelection';

export default function Header() {
  const { config, wording } = useConfig();

  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    let hasSubscription;

    const checkSubscription = async () => {
      hasSubscription = await (await fetch(`/api/cookie`)).json();
      if (hasSubscription) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    };
    checkSubscription();
  });

  return (
    <nav
      className='z-20 sm:h-[120px] sm:px-[105px] h-20 px-5 bg-white flex flex-row gap-14 justify-between shadow-lg'
      style={{ backgroundColor: config.theme.backgroundColor }}
    >
      <div className='flex flex-row items-center gap-2 sm:gap-4 min-w-[300px]'>
        <CustomLink href='/'>
          <img
            src={config.theme.logoSrc}
            className='sm:h-12 h-8'
            alt={`${config.variables?.SERVICE_NAME} logo`}
            fetchPriority='high'
          />
        </CustomLink>
        <CustomLink href='/'>
          <span
            className='text-neutral-800 sm:text-3xl text-[22.50px] font-extrabold ml-2.5 overflow-hidden whitespace-nowrap'
            style={{ color: config.theme.textColorDark }}
          >
            {config.variables?.SERVICE_NAME}
          </span>
        </CustomLink>

        {/* Menu language (mobile) */}
        <div className='sm:hidden ml-auto'>
          <LanguageSelection />
        </div>
      </div>
      <div className='hidden sm:flex flex-row justify-between items-center w-full'>
        <div className='flex gap-8'>
          {config.serviceType === SERVICE_TYPES.FITNESS && (
            // If we are in fitness we need to diplay the link with sub categories
            <div className='group'>
              <div className='text-neutral-800 text-xl font-bold flex flex-row p-6 cursor-pointer'>
                <span style={{ color: config.theme.textColorDark }}>
                  {wording?.components_header_videos_content}
                </span>
                <div>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g id='Chevron right'>
                      <path
                        id='Vector'
                        d='M18 9.99984L16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984Z'
                        fill={config.theme.textColorDark || '#000'}
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div
                className='z-20 absolute hidden bg-white shadow group-hover:block'
                style={{ backgroundColor: config.theme.backgroundColor }}
              >
                <CustomLink href='/videosContent/men'>
                  <div
                    className='text-neutral-800 w-[250px] text-xl font-bold p-6'
                    style={{ color: config.theme.textColorDark }}
                  >
                    {wording?.components_header_men}
                  </div>
                </CustomLink>
                <CustomLink href='/videosContent/women'>
                  <div
                    className='text-neutral-800 w-[250px] text-xl font-bold p-6'
                    style={{ color: config.theme.textColorDark }}
                  >
                    {wording?.components_header_women}
                  </div>
                </CustomLink>
              </div>
            </div>
          )}
          {config.serviceType !== SERVICE_TYPES.FITNESS && (
            // Else we just need a link to all the videos
            <CustomLink href='/videosContent/all'>
              <div
                className='text-neutral-800 text-xl font-bold p-6'
                style={{ color: config.theme.textColorDark }}
              >
                {wording?.components_header_videos_content}
              </div>
            </CustomLink>
          )}
          <CustomLink href='/recipes'>
            <div
              className='text-neutral-800 text-xl font-bold p-6'
              style={{ color: config.theme.textColorDark }}
            >
              {wording?.components_header_recipes}
            </div>
          </CustomLink>
          <CustomLink href='/articles'>
            <div
              className='text-neutral-800 text-xl font-bold p-6'
              style={{ color: config.theme.textColorDark }}
            >
              {wording?.components_header_blog}
            </div>
          </CustomLink>
        </div>

        <div className='flex items-center'>
          {/* Menu language (desktop) */}
          <div className='ml-4'>
            <LanguageSelection />
          </div>

          {!isAuth && config.variables && (
            <CustomLink href={config.variables.AUTH_REDIRECT_URL}>
              <div
                className='text-neutral-800 text-xl font-bold p-6'
                style={{ color: config.theme.textColorDark }}
              >
                {wording?.components_header_members}
              </div>
            </CustomLink>
          )}
        </div>
      </div>
    </nav>
  );
}
