export const LANGUAGE_OPTIONS = {
  EN: 'EN',
  EE: 'EE',
  FR: 'FR',
  LT: 'LT',
  LV: 'LV',
  RS: 'RS',
  PL: 'PL',
  BG: 'BG',
  FI: 'FI',
} as const;

export const SERVICE_TYPES = {
  FITNESS: 'fitness',
  PILATES: 'pilates',
  YOGA: 'yoga',
};

export const SERVICE_NAMES = {
  MyFitGoal: 'MyFitGoal',
  MyPilatesCoach: 'MyPilatesCoach',
  ZenZone: 'ZenZone',
  FitFlowClub: 'FitFlowClub',
  BodyZenMotion: 'BodyZenMotion',
  PilatesHorizon: 'PilatesHorizon',
  Apptitude: 'Apptitude',
} as const;
