'use client';

import { useEffect } from 'react';

const FaviconSetter = ({ faviconUrl }: { faviconUrl: string }) => {
  useEffect(() => {
    if (!faviconUrl) return;

    const setFavicon = (url: string) => {
      let link: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = url;
    };

    setFavicon(faviconUrl);
  }, [faviconUrl]);

  return null;
};

export default FaviconSetter;
