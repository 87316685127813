'use client';

import { Lato } from 'next/font/google';
import { useConfig } from '@/app/contexts/ConfigContext';
import CustomLink from './CustomLink';
import { isStringLongerThanMaxLength } from '@/helpers';
import { useEffect, useState } from 'react';

const lato = Lato({ subsets: ['latin'], weight: '700' });

export default function Footer() {
  const { config, wording } = useConfig();

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Use to execute client side only code so next can recognize window
    setIsClient(true);
  }, []);

  const textSpecificStyle = () => {
    if (
      isClient &&
      window.innerWidth < 380 &&
      isStringLongerThanMaxLength(config.variables?.SERVICE_NAME, 13)
    ) {
      return {
        fontSize: '30px',
      };
    }
  };

  return (
    <footer>
      <div
        className='bg-gray-800'
        style={{ backgroundColor: config.theme.backgroundColorFooter }}
      >
        <div className='py-9 sm:py-20 px-4 sm:px-44 flex flex-col sm:flex-row text-center sm:text-left sm:max-w-[1920px] max-w-[390px] mx-auto'>
          <div className='flex-1'>
            <div className='flex flex-row items-center sm:justify-start justify-center'>
              <img
                src={config.theme.logoSrc}
                alt={`${config.variables?.SERVICE_NAME} logo`}
                style={{
                  maxWidth: '47px',
                  maxHeight: '47px',
                }}
              />
              <span
                className='text-white font-extrabold text-[33.72px] ml-2.5 overflow-hidden whitespace-nowrap'
                style={{
                  color: config.theme.textColorLight,
                  ...textSpecificStyle(),
                }}
              >
                {config.variables?.SERVICE_NAME}
              </span>
            </div>
            <div
              className={`${lato.className} mt-5 text-stone-300 sm:text-[22.48px] text-lg font-medium capitalize sm:leading-loose leading-relaxed`}
              style={{ color: config.theme.textColorLightSecondary }}
            >
              {wording?.components_footer_text}
            </div>
          </div>
          <div className='flex-1 py-9 sm:py-0 sm:px-20'>
            <span
              className='text-white sm:text-[28.10px] font-bold sm:leading-[30.91px] text-2xl leading-relaxed'
              style={{ color: config.theme.textColorLight }}
            >
              {wording?.components_footer_info}
            </span>
            <div
              className={`${lato.className} mt-5 sm:mt-10 text-stone-300 sm:text-xl sm:leading-[29.34px] bg-gray-800 sm:text-left grid sm:grid-cols-1 sm:gap-2`}
              style={{
                color: config.theme.textColorLightSecondary,
                backgroundColor: config.theme.backgroundColorFooter,
              }}
            >
              <span>{config.variables.COMPANY_NAME}</span>
              <span>{config.variables.COMPANY_ADDRESS}</span>
              <span>
                {wording?.components_footer_registration_number}:{' '}
                {config.variables?.REGISTRATION_NUMBER}
              </span>
              <CustomLink
                href={`mailto:${config.variables?.SERVICE_CONTACT_EMAIL}`}
                className='hover:underline'
              >
                {config.variables?.SERVICE_CONTACT_EMAIL}
              </CustomLink>
            </div>
          </div>
          <div className='flex-1'>
            <span
              className='text-white sm:text-[28.10px] font-bold sm:leading-[30.91px] text-2xl leading-relaxed'
              style={{ color: config.theme.textColorLight }}
            >
              {wording?.components_footer_legal}
            </span>
            <div
              className={`${lato.className} mt-5 sm:mt-10 text-stone-300 sm:text-xl sm:leading-[29.34px] bg-gray-800 sm:text-left grid sm:grid-cols-1 sm:gap-5`}
              style={{ backgroundColor: config.theme.backgroundColorFooter }}
            >
              <CustomLink
                className='text-primary hover:underline'
                style={{ color: config.theme.accentColorOne }}
                href='/terms'
              >
                {wording?.components_footer_terms}
              </CustomLink>
              <CustomLink
                className='text-primary hover:underline'
                style={{ color: config.theme.accentColorOne }}
                href='privacy-policy'
              >
                {wording?.components_footer_privacy}
              </CustomLink>
            </div>
          </div>
        </div>
      </div>

      <div className='text-center'>
        <div
          className={`${lato.className} text-neutral-500 sm:text-[22.48px] font-medium capitalize sm:leading-[54.52px] text-lg leading-[43.65px]`}
          style={{ color: config.theme.textColorLightSecondary }}
        >
          Copyright ©{' '}
          <span
            className='text-secondary'
            style={{ color: config.theme.accentColorTwo }}
          >
            {config.variables?.SERVICE_NAME}
          </span>{' '}
          | 2024
        </div>
      </div>
    </footer>
  );
}
