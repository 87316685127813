'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useConfig } from '@/app/contexts/ConfigContext';
import Flag from 'react-world-flags';

const LanguageSelection: React.FC = () => {
  const { config, selectedLanguage, setLanguageAndWording } = useConfig();

  const languageOptions = config.languages;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Use to close the menu if user click out of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // If there is only one language option we don't display the menu
  if (config.languages.length === 1) return;

  return (
    <div className='relative inline-block text-left' ref={dropdownRef}>
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className='px-3 py-2 border rounded-md flex items-center gap-2 focus:outline-none h-8 min-w-[105px] max-w-[105px]'
        style={{
          color: config.theme.textColorDark,
          backgroundColor: config.theme.textColorLight,
        }}
      >
        <span className='flex items-center gap-2'>
          <Flag
            code={selectedLanguage === 'EN' ? 'GB' : selectedLanguage}
            style={{
              width: 30,
              height: 20,
              objectFit: 'cover',
              border: '1px solid #000',
            }}
          />
          <span className='text-sm leading-none'>{selectedLanguage}</span>
          <span className='text-xs leading-none'>▼</span>
        </span>
      </button>

      {isDropdownOpen && (
        <ul
          className='absolute mt-2 border shadow-lg'
          style={{
            zIndex: 10000,
            backgroundColor: config.theme.accentColorOne,
            borderColor: config.theme.backgroundColor,
          }}
        >
          {languageOptions.map((language) => {
            const flagCode = language === 'EN' ? 'GB' : language;

            // We don't display the already selected language
            if (language === selectedLanguage) return;

            return (
              <li
                key={language}
                className='px-3 py-2 cursor-pointer flex items-center gap-2 h-8 whitespace-nowrap min-w-[105px] max-w-[105px]'
                style={{
                  color: config.theme.textColorDark,
                  backgroundColor: config.theme.textColorLight,
                }}
                onMouseEnter={(e) => {
                  (e.target as HTMLLIElement).style.backgroundColor =
                    config.theme.accentColorOne;
                  (e.target as HTMLLIElement).style.color =
                    config.theme.textColorLight;
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLLIElement).style.backgroundColor =
                    config.theme.textColorLight;
                  (e.target as HTMLLIElement).style.color =
                    config.theme.textColorDark;
                }}
                onClick={() => {
                  setLanguageAndWording(config.serviceType, language);
                  setIsDropdownOpen(false);
                }}
              >
                <Flag
                  code={flagCode}
                  style={{
                    width: 30,
                    height: 20,
                    objectFit: 'cover',
                    border: '1px solid #000',
                  }}
                />
                <div className='text-sm leading-none'>{language}</div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelection;
