import Link, { LinkProps } from 'next/link';
import { CSSProperties, ReactNode } from 'react';

interface CustomLinkProps extends LinkProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const CustomLink: React.FC<CustomLinkProps> = ({ children, ...props }) => {
  return (
    <Link {...props} prefetch={false}>
      {children}
    </Link>
  );
};

export default CustomLink;
